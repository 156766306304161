export default function SelectCheckbox({ isChecked, setIsChecked, selectedValue, setSelectedValue, children, description }) {
    const handleCheckboxChange = (e) => {
        if(setIsChecked)
            setIsChecked(e.target.checked);
    };

    const handleSelectChange = (e) => {
        if(setSelectedValue)
            setSelectedValue(e.target.value);
    };

    return (
        <div className="d-flex flex-column align-items-start w-100 my-2 mx-3">
            {description && <p className="mb-0 ms-5">{description}</p>}
            <div className="input-group mb-1 w-100">
                <div className="input-group-text">
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        aria-label="Checkbox for following text input"
                    />
                </div>
                <select
                    className="form-select"
                    value={selectedValue}
                    onChange={handleSelectChange}
                >
                    {children}
                </select>
            </div>
        </div>
    );
}
