import React, { useState, useEffect } from "react";
import {
    useLoaderData,
    useNavigate,
    redirect,
} from "react-router-dom";
import { getInvoiceList } from '../../services/invoice.service';
import { getUnit } from '../../services/unit.service';
import { getResource } from '../../services/resource.service';
import { serializeQueryString } from "../../helpers/url.helper";
//import DataTable from "react-data-table-component";
//import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
//import { AgGridReact } from 'ag-grid-react';
//import "ag-grid-community/styles/ag-grid.css";
//import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import BaseListControl, { currencyFormatter } from '../../components/BaseListControl'
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { PlusCircle, Search, ShieldCheck } from 'react-bootstrap-icons';
import BooleanControl from "../../components/BooleanControl";


const columns = [
    {
        headerName: "Fakturanr",
        field: "number",
        filter: true,
        width: 113,
    },
    {
        headerName: "Kund",
        field: "customerName",
        filter: true,
        width: 250,
    },
    {
        headerName: "Fakturadatum",
        field: "invoiceDate",
        width: 150,
    },
    {
        headerName: "Förfallodatum",
        field: "dueDate",
        width: 150,
    },
    {
        headerName: "Belopp",
        field: "netAmount",
        valueFormatter: currencyFormatter,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Moms",
        field: "vatAmount",
        valueFormatter: currencyFormatter,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Fakturabelopp",
        field: "invoiceAmount",
        valueFormatter: currencyFormatter,
        width: 140,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Makulerad",
        field: "isCanceled",
        width: 120,
    },
    {
        headerName: "Status",
        field: "statusName",
        width: 183,
    },
]
let navigate;

//const data = (customerList) => {

//    return {
//        columns: columns,
//        rows: customerList
//    }
//}


export default function InvoiceList() {
    const { invoiceList, loaderFilter } = useLoaderData();
    const [filter, setFilter] = useState(loaderFilter);
    navigate = useNavigate();

    const search = () => {
        navigate("?" + serializeQueryString(filter));
    };

    useEffect(() => {
        search();
    }, [filter]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            navigate("/invoice/create");
                        }}
                        >
                            <PlusCircle /> Skapa
                        </button>
                        <button className="btn" onClick={() => {
                            search();
                        }}
                        >
                            <Search /> Sök
                        </button>
                        <ToolbarSeparatorControl />
                        <button className="btn" onClick={() => {
                            navigate("/fortnox");
                        }}
                        >
                            <ShieldCheck /> Fortnox
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <div className="row">
                    <div className="col mb-3">

                        <CardControl className="border-1">
                            <CardBodyControl>
                                <div className="row">
                                    <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                                        <label className="form-label">From</label>
                                        <input type="date" className="form-control" defaultValue={filter.fromInvoiceDate} onChange={(e) => {
                                            setFilter({ ...filter, fromInvoiceDate: e.target.value });
                                        }} />
                                    </div>
                                    <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                                        <label className="form-label">Tom</label>
                                        <input type="date" className="form-control" defaultValue={filter.toInvoiceDate} onChange={(e) => {
                                            setFilter({ ...filter, toInvoiceDate: e.target.value });
                                        }} />
                                    </div>
                                    <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                                        <label className="form-label">Makulerad</label>
                                        <BooleanControl value={filter.isCanceled} onChange={(e) => {
                                            setFilter({ ...filter, isCanceled: e });
                                        }} />
                                    </div>
                                </div>
                            </CardBodyControl>
                        </CardControl>
                    </div>
                </div>
                <BaseListControl columns={columns} list={invoiceList} onRowClicked={rowClicked} tableName="Fakturering" />
            </CardBodyControl>
        </CardControl>
    );
}

function rowClicked(e) {
    const id = e.data.id;
    navigate("/invoice/" + id);
}

export async function loader({ msalInstance, request }) {

    //console.log({ msalInstance: msalInstance, request: request.request, params: request.params });

    let dateFrom = new URL(request.request.url).searchParams.get('fromInvoiceDate');
    let dateTo = new URL(request.request.url).searchParams.get('toInvoiceDate');
    let isCanceled = new URL(request.request.url).searchParams.get('isCanceled');
    
    if (!dateFrom) {
        var today = new Date();
        today.setDate(1);
        dateFrom = today.toISOString().split('T')[0];
    }
    if (!dateTo) {
        var today = new Date();
        today.setMonth(today.getMonth() + 1, 1);
        today.setDate(0);
        dateTo = today.toISOString().split('T')[0];
    }

    if (isCanceled === undefined) {
        isCanceled = false;
    }

    let filter = {
        fromInvoiceDate: dateFrom,
        toInvoiceDate: dateTo,
        isCanceled
        //pageSize: 10
    }
    const invoiceList = await getInvoiceList(msalInstance, filter);
    return { invoiceList, loaderFilter: filter };
}
