import { getAccessToken } from './base.service';
import {
    getCategory as getCategoryRepo,
    getCategoryList as getCategoryListRepo,
    createCategory as createCategoryRepo,
    updateCategory as updateCategoryRepo,
    deleteCategory as deleteCategoryRepo,
} from '../repositories/repository';

export async function getCategory(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getCategoryRepo(accessToken, id, includes);
}

export async function getCategoryList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCategoryListRepo(accessToken, filter);
}

export async function createCategory(msalInstance, category) {

    //console.log({ msalInstance, category });
    const accessToken = await getAccessToken(msalInstance);
    return await createCategoryRepo(accessToken, category);
}

export async function updateCategory(msalInstance, category, fields) {

    //console.log({ msalInstance, category, fields });
    const accessToken = await getAccessToken(msalInstance);
    return await updateCategoryRepo(accessToken, category, fields);
}

export async function deleteCategory(msalInstance, id) {

    //console.log({ msalInstance, id });
    const accessToken = await getAccessToken(msalInstance);
    return await deleteCategoryRepo(accessToken, id);
}
