import { getAccessToken } from './base.service';
import {
    getCost as getCostRepo,
    getCostList as getCostListRepo,
    getCostSummaryList as getCostSummaryListRepo,
    getCostWeekInfo as getCostWeekInfoRepo,
    getCostWeek as getCostWeekRepo,
    createCost as createCostRepo,
    updateCost as updateCostRepo,
    deleteCost as deleteCostRepo,
} from './../repositories/repository';

export async function getCost(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getCostRepo(accessToken, id, includes);
}

export async function getCostList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCostListRepo(accessToken, filter);
}
export async function getCostSummaryList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCostSummaryListRepo(accessToken, filter);
}
export async function getCostWeekInfo(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCostWeekInfoRepo(accessToken, filter);
}

export async function getCostWeek(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCostWeekRepo(accessToken, filter);
}

export async function createCost(msalInstance, customer) {

    //console.log({ msalInstance, customer });
    const accessToken = await getAccessToken(msalInstance);
    return await createCostRepo(accessToken, customer);
}

export async function updateCost(msalInstance, customer, fields) {

    //console.log({ msalInstance, customer, fields });
    const accessToken = await getAccessToken(msalInstance);
    return await updateCostRepo(accessToken, customer, fields);
}

export async function deleteCost(msalInstance, id) {

    //console.log({ msalInstance, id });
    const accessToken = await getAccessToken(msalInstance);
    return await deleteCostRepo(accessToken, id);
}
