import { getAccessToken } from './base.service';
import {
    getResource as getResourceRepo,
    getResourceList as getResourceListRepo,
    createResource as createResourceRepo,
    updateResource as updateResourceRepo,
    deleteResource as deleteResourceRepo,
} from './../repositories/repository';

export async function getResource(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getResourceRepo(accessToken, id, includes);
}

export async function getResourceList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getResourceListRepo(accessToken, filter);
}

export async function createResource(msalInstance, resource) {

    //console.log({ msalInstance, resource });
    const accessToken = await getAccessToken(msalInstance);
    return await createResourceRepo(accessToken, resource);
}

export async function updateResource(msalInstance, resource, fields) {

    //console.log({ msalInstance, resource, fields });
    const accessToken = await getAccessToken(msalInstance);
    return await updateResourceRepo(accessToken, resource, fields);
}

export async function deleteResource(msalInstance, id) {

    //console.log({ msalInstance, id });
    const accessToken = await getAccessToken(msalInstance);
    return await deleteResourceRepo(accessToken, id);
}
