import { getAccessToken } from './base.service';
import {
    getPartStatus as getPartStatusRepo,
    getPartStatusList as getPartStatusListRepo,
} from './../repositories/repository';

export async function getPartStatus(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getPartStatusRepo(accessToken, id, includes);
}

export async function getPartStatusList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getPartStatusListRepo(accessToken, filter);
}
