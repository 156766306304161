import { getAccessToken } from './base.service';
import {
    getFortnoxClientId as getFortnoxClientIdRepo,
    updateFortnoxCredentials as updateFortnoxCredentialsRepo,
    refreshFortnoxAccessToken as refreshFortnoxAccessTokenRepo,
} from './../repositories/repository';

export async function getClientId(msalInstance) {

    const accessToken = await getAccessToken(msalInstance);
    return await getFortnoxClientIdRepo(accessToken);
}

export async function updateCredentials(msalInstance, entity) {

    const accessToken = await getAccessToken(msalInstance);
    return await updateFortnoxCredentialsRepo(accessToken, entity);
}

export async function refreshAccessToken(msalInstance, entity) {

    const accessToken = await getAccessToken(msalInstance);
    return await refreshFortnoxAccessTokenRepo(accessToken, entity);
}
