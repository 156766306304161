import React, { useState, useEffect } from "react";
import {
    useLoaderData,
    useNavigate,
    redirect,
} from "react-router-dom";
import { getCostList } from '../../services/cost.service';
import { getUnit } from '../../services/unit.service';
import { getResource } from '../../services/resource.service';
import { serializeQueryString } from "../../helpers/url.helper";
//import DataTable from "react-data-table-component";
//import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
//import { AgGridReact } from 'ag-grid-react';
//import "ag-grid-community/styles/ag-grid.css";
//import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import BaseListControl, { currencyFormatter } from '../../components/BaseListControl'
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl from "../../components/ToolbarControl";
import { PlusCircle, Search } from 'react-bootstrap-icons';
import UnitControl from "../../components/UnitControl";


const columns = [
    {
        headerName: "Projektdel",
        field: "partNamePath",
        width: 400,
        // sort: "disable",
    },
    {
        headerName: "Kategori",
        field: "categoryNamePath",
        width: 400,
        // sort: "disable",
    },
    {
        headerName: "Datum",
        field: "date",
        width: 116,
    },
    {
        headerName: "Antal",
        field: "quantity",
        width: 85,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Enhet",
        field: "unitName",
        width: 100,
    },
    {
        headerName: "Enhetspris",
        field: "unitPrice",
        valueFormatter: currencyFormatter,
        width: 120,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Belopp",
        field: "netAmount",
        valueFormatter: currencyFormatter,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Beskrivning",
        field: "description",
    },
    {
        headerName: "Typ",
        field: "costTypeName",
        width: 150,
    },
    {
        headerName: "Låst",
        field: "isLocked",
        width: 120,
    },
]
let navigate;

//const data = (customerList) => {

//    return {
//        columns: columns,
//        rows: customerList
//    }
//}


export default function CostList() {
    const { costList, loaderFilter } = useLoaderData();
    const [filter, setFilter] = useState(loaderFilter);
    navigate = useNavigate();

    const search = () => {
        //console.log({ filter });
        navigate("?" + serializeQueryString(filter));
    };

    useEffect(() => {
        search();
    }, [filter]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            navigate("/cost/create");
                        }}
                        >
                            <PlusCircle /> Skapa
                        </button>
                        <button className="btn" onClick={() => {
                            search();
                        }}
                        >
                            <Search /> Sök
                        </button>
                        <button className="btn" onClick={() => {
                            navigate("/cost");
                        }}
                        >
                            <Search /> Visa veckovy
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <div className="row">
                    <div className="col mb-3">

                        <CardControl className="border-1">
                            <CardBodyControl>
                                <div className="row">
                                    <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                                        <label className="form-label">Enhet</label>
                                        <UnitControl value={filter.unitId} onChange={(unitId) => {
                                            setFilter({ ...filter, unitId });
                                            //search();
                                        }} />
                                    </div>
                                    <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                                        <label className="form-label">From</label>
                                        <input type="date" className="form-control" defaultValue={filter.dateFrom} onChange={(e) => {
                                            setFilter({ ...filter, dateFrom: e.target.value });
                                            //search();
                                        }} />
                                    </div>
                                    <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                                        <label className="form-label">Tom</label>
                                        <input type="date" className="form-control" defaultValue={filter.dateTo} onChange={(e) => {
                                            setFilter({ ...filter, dateTo: e.target.value });
                                            //search();
                                        }} />
                                    </div>
                                </div>
                            </CardBodyControl>
                        </CardControl>
                    </div>
                </div>
                <BaseListControl columns={columns} list={costList} onRowClicked={rowClicked} tableName="Tidsredovisning"/>
            </CardBodyControl>
        </CardControl>
    );
}

function rowClicked(e) {
    const id = e.data.id;
    if (id === "00000000-0000-0000-0000-000000000000") return; //Botten raden
    navigate("/cost/" + id);
}

export async function loader({ msalInstance, request }) {

    //console.log({ msalInstance: msalInstance, request: request.request, params: request.params });

    let dateFrom = new URL(request.request.url).searchParams.get('dateFrom');
    let dateTo = new URL(request.request.url).searchParams.get('dateTo');
    let resourceId = new URL(request.request.url).searchParams.get('resourceId');
    let unitId = new URL(request.request.url).searchParams.get('unitId');

    var day = ((new Date().getDay() + 6) % 7);
    if (!dateFrom) {
        var monday = new Date();
        monday.setDate(monday.getDate() - day);
        dateFrom = monday.toISOString().split('T')[0];
    }
    if (!dateTo) {
        var sunday = new Date();
        sunday.setDate(sunday.getDate() - day + 6);
        dateTo = sunday.toISOString().split('T')[0];
    }

    if (resourceId === undefined) {
        const defaultResource = await getResource(msalInstance, 'default');
        resourceId = defaultResource.id ?? null;
    }

    if (unitId === undefined) {
        const defaultUnit = await getUnit(msalInstance, 'default');
        unitId = defaultUnit.id ?? null;
    }

    let filter = {
        unitId,
        resourceId,
        dateFrom,
        dateTo,
        //pageSize: 10
    }
    const costList = await getCostList(msalInstance, filter);
    return { costList, loaderFilter: filter };
}
