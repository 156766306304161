import React, { useState, useEffect, useCallback } from "react";
import {
    NavLink,
    useLoaderData,
    useNavigate,
} from "react-router-dom";
//import { getAccessToken as getAccessTokenRepo } from "../../repositories/fortnox.repository";
import { useMsal } from "@azure/msal-react";
import { Hdd, BoxArrowInLeft } from 'react-bootstrap-icons';
import {
    getClientId,
    updateCredentials,
} from '../../services/fortnox.service';
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";

export default function Credentials() {

    const { savedClientId } = useLoaderData();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [clientId, setClientId] = useState(savedClientId ?? "");
    const [clientSecret, setClientSecret] = useState("");
    const onUpdateCredentials = useCallback(() => {
        const entity = { clientId, clientSecret }
        updateCredentials(instance, entity).then((result) => {
            //console.log({ result });
            setClientSecret("");
        }).catch(errorHandler);
    }, [clientId, clientSecret]);
    useEffect(() => { setClientId(savedClientId); }, [savedClientId]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={onUpdateCredentials} disabled={!clientId || !clientSecret}>
                            <Hdd /> Spara
                        </button>
                        <button className="btn" onClick={() => {
                            navigate("/fortnox");
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Uppdatera klientsäkerheter mot Fortnox</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                        <label className="form-label">Client Id</label>
                        <input type="text" className="form-control" defaultValue={clientId} onChange={(e) => {
                            setClientId(e.target.value);
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                        <label className="form-label">Client Secret</label>
                        <input type="password" className="form-control" defaultValue={clientSecret} onChange={(e) => {
                            setClientSecret(e.target.value);
                        }} />
                    </div>
                </div>
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request, params }) {

    //let code = new URL(request.request.url).searchParams.get('code');
    //let state = new URL(request.request.url).searchParams.get('state');
    //let url = new URL(request.request.url);
    //let currentUrl = url.protocol + '//' + url.host + url.pathname;
    const clientId = await getClientId(msalInstance);

    return { savedClientId: clientId.clientId };
}
