import React, { useState, useEffect } from "react";
import {
    useLoaderData,
    useNavigate,
    redirect,
} from "react-router-dom";
import { getCostWeekInfo, getCostWeek, getCostList } from '../../services/cost.service';
import { getUnit } from '../../services/unit.service';
import { getResource } from '../../services/resource.service';
import { serializeQueryString } from "../../helpers/url.helper";
import { useMsal } from "@azure/msal-react";

import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl from "../../components/ToolbarControl";
import { PlusCircle, Search } from 'react-bootstrap-icons';
import UnitControl from "../../components/UnitControl";
import ResourceControl from "../../components/ResourceControl";
import { errorHandler } from "../../error";

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


let navigate;

export default function CostIndex() {
    const { costWeekInfo, costWeek, loaderFilter } = useLoaderData();
    const [filter, setFilter] = useState(loaderFilter);
    const { instance } = useMsal();
    navigate = useNavigate();

    const search = () => {
        navigate("?" + serializeQueryString(filter));
    };

    useEffect(() => {
        search();
    }, [filter]);

    //const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    //const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

    const popoverHeader = (x) => (
        <Popover className="p-2" title="Popover left">
            <div className="text-center">{x.date}<br />{x.name}</div>
        </Popover>
    );

    const renderHeader = () => {
        return (
            <div className="row">
                <div className="col col-lg-3 col-xl-4 bg-primary border-primary py-lg-2">Projekt</div>
                <div className="col col-lg-3 col-xl-4 bg-primary border-primary text-end py-lg-2">Vecka: {costWeekInfo.weekNumber}</div>
                <div className="w-100 d-block d-lg-none"></div>
                {costWeekInfo.days.map((x) => renderHeaderDays(x))}
                <div className="col  bg-primary border-primary text-center px-1 py-2">Totalt</div>
            </div>
        )
    }

    const renderHeaderDays = (x) => (
        <div className="col bg-primary border-primary text-center p-0" key={x.date}>
            <OverlayTrigger trigger={["hover", "focus"]} overlay={popoverHeader(x)}>
                <button className="btn btn-primary m-0 w-100 h-100" onClick={(e) => { dateClicked(e, x.date) }}>{x.shortName}</button>
            </OverlayTrigger>
        </div>
    );

    const renderItems = () => {
        return costWeek.map((x) => renderCategory(x));
    }

    const renderCategory = (x) => (
        <div className="row" key={x.id}>
            <div className="col col-lg-6 col-xl-8 bg-light border border-top-0 border-secondary p-0">
                <button className="btn btn-light m-0 py-2 w-100 h-100 text-start" onClick={(e) => { categoryClicked(e, x.id) }}>{x.partNamePath} / {x.categoryNamePath}</button>
            </div>
            <div className="w-100 d-block d-lg-none"></div>
            <div className="col bg-light border     border-top-0  border-secondary text-center p-0">
                <button className="btn btn-light m-0 w-100 h-100" onClick={(e) => { categoryDateClicked(e, x.id, x.dateOfMonday) }}>&nbsp;{x.quantityMonday}&nbsp;</button>
            </div>
            <div className="col bg-light border     border-top-0  border-secondary text-center p-0">
                <button className="btn btn-light m-0 w-100 h-100" onClick={(e) => { categoryDateClicked(e, x.id, x.dateOfTuesday) }}>&nbsp;{x.quantityTuesday}&nbsp;</button>
            </div>
            <div className="col bg-light border     border-top-0  border-secondary text-center p-0">
                <button className="btn btn-light m-0 w-100 h-100" onClick={(e) => { categoryDateClicked(e, x.id, x.dateOfWednesday) }}>&nbsp;{x.quantityWednesday}&nbsp;</button>
            </div>
            <div className="col bg-light border     border-top-0  border-secondary text-center p-0">
                <button className="btn btn-light m-0 w-100 h-100" onClick={(e) => { categoryDateClicked(e, x.id, x.dateOfThursday) }}>&nbsp;{x.quantityThursday}&nbsp;</button>
            </div>
            <div className="col bg-light border     border-top-0  border-secondary text-center p-0">
                <button className="btn btn-light m-0 w-100 h-100" onClick={(e) => { categoryDateClicked(e, x.id, x.dateOfFriday) }}>&nbsp;{x.quantityFriday}&nbsp;</button>
            </div>
            <div className="col bg-light border     border-top-0  border-secondary text-center p-0">
                <button className="btn btn-light m-0 w-100 h-100" onClick={(e) => { categoryDateClicked(e, x.id, x.dateOfSaturday) }}>&nbsp;{x.quantitySaturday}&nbsp;</button>
            </div>
            <div className="col bg-light border     border-top-0  border-secondary text-center p-0">
                <button className="btn btn-light m-0 w-100 h-100" onClick={(e) => { categoryDateClicked(e, x.id, x.dateOfSunday) }}>&nbsp;{x.quantitySunday}&nbsp;</button>
            </div>
            <div className="col torin-bg-footer border-end border-bottom border-secondary text-center p-auto text-black px-0 py-2">{x.total}</div>
        </div>
    )

    const renderFooter = () => {

        let total, mon, tue, wed, thu, fri, sat, sun;
        // let bg_total, bg_mon, bg_tue, bg_wed, bg_thu, bg_fri, bg_sat, bg_sun;

        for (var i = 0; i < costWeek.length; i++) {
            let c = costWeek[i];
            if (c.quantityMonday) mon = mon ? mon + c.quantityMonday : c.quantityMonday;
            if (c.quantityTuesday) tue = tue ? tue + c.quantityTuesday : c.quantityTuesday;
            if (c.quantityWednesday) wed = wed ? wed + c.quantityWednesday : c.quantityWednesday;
            if (c.quantityThursday) thu = thu ? thu + c.quantityThursday : c.quantityThursday;
            if (c.quantityFriday) fri = fri ? fri + c.quantityFriday : c.quantityFriday;
            if (c.quantitySaturday) sat = sat ? sat + c.quantitySaturday : c.quantitySaturday;
            if (c.quantitySunday) sun = sun ? sun + c.quantitySunday : c.quantitySunday;
            if (c.total) total = total ? total + c.total : c.total;
        }

        let bg_total = costWeekInfo.workQuantity == 0 ? "torin-bg-footer" : (total ?? 0) < costWeekInfo.workQuantity ? "torin-bg-danger" : "torin-bg-success";
        let bg_mon = costWeekInfo.days[0].workQuantity == 0 ? "torin-bg-footer" : (mon ?? 0) < costWeekInfo.days[0].workQuantity ? "torin-bg-danger" : "torin-bg-success";
        let bg_tue = costWeekInfo.days[1].workQuantity == 0 ? "torin-bg-footer" : (tue ?? 0) < costWeekInfo.days[1].workQuantity ? "torin-bg-danger" : "torin-bg-success";
        let bg_wed = costWeekInfo.days[2].workQuantity == 0 ? "torin-bg-footer" : (wed ?? 0) < costWeekInfo.days[2].workQuantity ? "torin-bg-danger" : "torin-bg-success";
        let bg_thu = costWeekInfo.days[3].workQuantity == 0 ? "torin-bg-footer" : (thu ?? 0) < costWeekInfo.days[3].workQuantity ? "torin-bg-danger" : "torin-bg-success";
        let bg_fri = costWeekInfo.days[4].workQuantity == 0 ? "torin-bg-footer" : (fri ?? 0) < costWeekInfo.days[4].workQuantity ? "torin-bg-danger" : "torin-bg-success";
        let bg_sat = costWeekInfo.days[5].workQuantity == 0 ? "torin-bg-footer" : (sat ?? 0) < costWeekInfo.days[5].workQuantity ? "torin-bg-danger" : "torin-bg-success";
        let bg_sun = costWeekInfo.days[6].workQuantity == 0 ? "torin-bg-footer" : (sun ?? 0) < costWeekInfo.days[6].workQuantity ? "torin-bg-danger" : "torin-bg-success";

        return (
            <div className="row">
                <div className="col col-lg-6 col-xl-8 torin-bg-footer text-black border border-secondary py-lg-2">Totalt:</div>
                <div className="w-100 d-block d-lg-none"></div>
                <div className={"col text-center text-black border border-secondary px-1 py-2 " + bg_mon}>{mon}</div>
                <div className={"col text-center text-black border border-secondary px-1 py-2 " + bg_tue}>{tue}</div>
                <div className={"col text-center text-black border border-secondary px-1 py-2 " + bg_wed}>{wed}</div>
                <div className={"col text-center text-black border border-secondary px-1 py-2 " + bg_thu}>{thu}</div>
                <div className={"col text-center text-black border border-secondary px-1 py-2 " + bg_fri}>{fri}</div>
                <div className={"col text-center text-black border border-secondary px-1 py-2 " + bg_sat}>{sat}</div>
                <div className={"col text-center text-black border border-secondary px-1 py-2 " + bg_sun}>{sun}</div>
                <div className={"col text-center text-black border border-secondary px-1 py-2 " + bg_total}>{total}</div>
            </div>
        )
    };

    function dateClicked(e, date) {
        let obj = { resourceId: filter.resourceId, date };
        navigate("/cost/create?" + serializeQueryString(obj));
    }

    function categoryClicked(e, categoryId) {
        let obj = { resourceId: filter.resourceId, categoryId };
        navigate("/cost/create?" + serializeQueryString(obj));
    }

    function categoryDateClicked(e, categoryId, date) {
        //console.log({ categoryId, date });
        let obj = { categoryId, resourceId: filter.resourceId, date, unitId: filter.unitId };
        getCostList(instance, obj).then((data) => {
            console.log(data);
            if (!data || data.length == 0) {
                obj = { ...obj, unitId: undefined }
                navigate("/cost/create?" + serializeQueryString(obj));
            }
            else if (data.length == 1) {
                navigate("/cost/" + data[0].id);
            }
            else {
                alert("antal: " + data.length);
            }
        }).catch(errorHandler);
    }

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            navigate("create");
                        }}
                        >
                            <PlusCircle /> Skapa
                        </button>
                        <button className="btn" onClick={() => {
                            search();
                        }}
                        >
                            <Search /> Sök
                        </button>
                        <button className="btn" onClick={() => {
                            navigate("list");
                        }}
                        >
                            <Search /> Visa listvy
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <div className="row">
                    <div className="col mb-3">

                        <CardControl className="border-1">
                            <CardBodyControl>
                                <div className="row">
                                    <div className="col col-sm-3 col-md-3 col-lg-2 col-6">
                                        <label className="form-label">Enhet</label>
                                        <UnitControl value={filter.unitId} onChange={(unitId) => {
                                            setFilter({ ...filter, unitId });
                                            //search();
                                        }} />
                                    </div>
                                    <div className="col col-sm-4 col-md-3 col-lg-2 col-6">
                                        <label className="form-label">Datum</label>
                                        <input type="date" className="form-control" defaultValue={filter.date} onChange={(e) => {
                                            setFilter({ ...filter, date: e.target.value });
                                            //search();
                                        }} />
                                    </div>
                                    <div className="col col-sm-5 col-md-5 col-lg-4 col-12">
                                        <label className="form-label">Resurs</label>
                                        <ResourceControl value={filter.resourceId} onChange={(resourceId) => {
                                            setFilter({ ...filter, resourceId });
                                            //search();
                                        }} />
                                    </div>
                                </div>
                            </CardBodyControl>
                        </CardControl>
                    </div>
                </div>
                {renderHeader()}
                {renderItems()}
                {renderFooter()}
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {

    //console.log({ msalInstance: msalInstance, request: request.request, params: request.params });

    let date = new URL(request.request.url).searchParams.get('date');
    let resourceId = new URL(request.request.url).searchParams.get('resourceId');
    let unitId = new URL(request.request.url).searchParams.get('unitId');

    var day = ((new Date().getDay() + 6) % 7);
    if (!date) {
        var monday = new Date();
        monday.setDate(monday.getDate() - day);
        date = monday.toISOString().split('T')[0];
    }

    if (resourceId === undefined) {
        const defaultResource = await getResource(msalInstance, 'default');
        resourceId = defaultResource.id ?? null;
    }

    if (unitId === undefined) {
        const defaultUnit = await getUnit(msalInstance, 'default');
        unitId = defaultUnit.id ?? null;
    }

    let filter = {
        unitId,
        resourceId,
        date,
        //pageSize: 10
    }
    const costWeekInfo = await getCostWeekInfo(msalInstance, filter);
    const costWeek = await getCostWeek(msalInstance, filter);
    return { costWeekInfo, costWeek, loaderFilter: filter };
}
