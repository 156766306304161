export function getFields(entity, original) {
    var fields = [];
    for (var prop in entity) {
        if (entity[prop] !== original[prop]) {
            fields.push(prop);
        }
    }

    console.log({ fields });
    return fields;
}

export function formatCurrency(number) {
    var n = Number(number);
    if (!n) {
        return "";
    }

    return formatNumber(n) + ' kr';
}
export function formatNumber(number) {
    return number.toLocaleString(undefined, { minimumFractionDigits: 2 });
}
