import { torinLoginRequest } from './../authConfig';

export async function getAccessToken(msalInstance) {
    await msalInstance.initialize();
    const accounts = msalInstance.getAllAccounts();

    const request = {
        ...torinLoginRequest,
        account: accounts[0], //msalInstance.getActiveAccount(),
    };

    return await msalInstance.acquireTokenSilent(request)
        .then(response => response.accessToken)
        .catch(error => {
            console.error(error);
        });
}