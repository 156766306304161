import { useEffect, useState } from 'react';
import 'react-select-search/style.css';
import SelectSearch from 'react-select-search';
import { classNames } from "./BaseSelectSearch";

//import { useSelect } from 'react-select-search';

export default function BooleanControl(props) {
    const [value, setValue] = useState(props.value ?? null);
    const [options] = useState([{ value: null, name: "Alla" }, { value: 1, name: "Ja" }, { value: 2, name: "Nej" }]);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);

    useEffect(() => {
        setReadOnly(props.readOnly ?? false);
        if (props.value !== undefined) {
            if (props.value === true) {
                setValue(1);
            }
            else if (props.value === false) {
                setValue(2);
            }
            else {
                setValue(null);
            }
        }
    }, [props]);

    return (
        <SelectSearch options={options} value={value} disabled={readOnly} placeholder="Välj typ" className={classNames} onChange={(val, item) => {
            setValue(val);
            if (props.onChange) {
                switch (val) {
                    case 2:
                        console.log("onChange: false");
                        props.onChange(false);
                        break;
                    case 1:
                        console.log("onChange: true");
                        props.onChange(true);
                        break;
                    default:
                        console.log("onChange: null: " + val);
                        props.onChange(null);
                        break;
                }
            }
        }} />
    )
}

// https://www.npmjs.com/package/react-select-search