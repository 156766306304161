import {
    NavLink,
} from "react-router-dom";

export default function Index() {
    return (
        <>
            <NavLink className="btn btn-primary bg-primary m-2 p-3" style={{ maxWidth: '18rem' }} to={"cost"}>
                <div className="card-body">
                    <h5 className="card-title">Rapportering</h5>
                    <p className="card-text">Här registrerar du din tid och annan rapportering</p>
                </div>
            </NavLink>
        </>
    );
}