export default function CardControl(props) {
    const className = "card w-100 h-100" + (props.className ? " " + props.className : "");

    return (
        <div className={className} id={props.id}>
            {props.children}
        </div>
    )
}

export function CardHeaderControl({ children }) {

    return (
        <div className="card-header">
            {children}
        </div>
    )
}

export function CardBodyControl({ children }) {
    return (
        <div className="card-body">
            {children}
        </div>
    )
}
