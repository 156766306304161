import React, { useState, useEffect, useCallback } from "react";
import {
    NavLink,
    useLoaderData,
    useNavigate,
} from "react-router-dom";
//import { getAccessToken as getAccessTokenRepo } from "../../repositories/fortnox.repository";
import { useMsal } from "@azure/msal-react";
import { DoorOpen, BoxArrowInLeft } from 'react-bootstrap-icons';
import {
    getClientId,
    updateCredentials,
    refreshAccessToken
} from '../../services/fortnox.service';
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";

export default function Index() {

    const { code, state, currentUrl, savedClientId } = useLoaderData();
    const { instance } = useMsal();
    const navigate = useNavigate();
    const [clientId, setClientId] = useState(savedClientId ?? "");
    const [url, setUrl] = useState(null);
    useEffect(() => {
        if (clientId) {
            const myState = "Torin";
            let u = "https://apps.fortnox.se/oauth-v1/auth?client_id=" + clientId + "&scope=invoice&state=" + myState + "&access_type=offline&response_type=code&account_type=service&redirect_uri=" + currentUrl;
            setUrl(u);
        }
        else {
            setUrl(null);
        }
    }, [currentUrl, clientId]);
    useEffect(() => { setClientId(savedClientId); }, [savedClientId]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" disabled={!url} onClick={() => {
                            window.open(url, "_top");
                            // navigate(url);
                        }}>
                            <DoorOpen /> Logga in i Fortnox
                        </button>
                        <button className="btn" onClick={() => {
                            navigate("/invoice");
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Logga in Fortnox</li>
                    </ol>
                </nav>
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request, params }) {

    let url = new URL(request.request.url);
    let currentUrl = url.protocol + '//' + url.host + url.pathname + "/code";
    const clientId = await getClientId(msalInstance);

    return { currentUrl, savedClientId: clientId.clientId };
}
