import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getProjectFormatList } from '../services/project.service';
import 'react-select-search/style.css';
import SelectSearch from 'react-select-search';
import { errorHandler } from "../error";
import { classNames } from "./BaseSelectSearch";

//import { useSelect } from 'react-select-search';

export default function ProjectFormatControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [options, setOptions] = useState([]);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;

    useEffect(() => {
        const filter = { orderBy: [{ field: "sortOrder" }] };
        //console.log({ value });
        getProjectFormatList(instance, filter).then((formats) => {
            if (!value) {
                let defaultFormat = formats.find((x) => (x.isDefault));
                if (defaultFormat) {
                    setValue(defaultFormat.id);
                    if (onChange) {
                        onChange(defaultFormat.id, defaultFormat);
                    }
                }
            }
            setOptions(formats.map((p) => ({ value: p.id, name: p.name, data: p })));
        }).catch(errorHandler);
    }, [instance, onChange]);

    useEffect(() => {
        if (props.readOnly !== undefined) {
            setReadOnly(props.readOnly);
        }
    }, [props]);

    return (
        <SelectSearch options={options} value={value} disabled={readOnly}  placeholder="Välj format" className={classNames} onChange={(value, item) => {
            //console.log({ value, item });
            setValue(value);
            if (onChange) {
                onChange(value, item);
            }
        }} />
    )
}

// https://www.npmjs.com/package/react-select-search