import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl from "../../components/ToolbarControl";
import SelectCheckbox from "../../components/SelectCheckbox";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from 'react';
import DateCheckbox from "../../components/DateCheckbox";
import { getResourceList } from "../../services/resource.service";
import { getUnitList } from "../../services/unit.service";
import CategoryControl from "../../components/CategoryControl";
import CategoryCheckbox from "../../components/CategoryCheckbox";
import { Search } from 'react-bootstrap-icons';

export default function Summary() {
    const [resources, setResources] = useState({});
    const [units, setUnits] = useState({});

    const { instance } = useMsal();

    const [unitId, setUnitId] = useState("");
    const [resourceId, setResourceId] = useState("");
    const [categoryId, setCategoryId] = useState(null);

    const [unitToggle, setUnitToggle] = useState(false);
    const [resourceToggle, setResourceToggle] = useState(false);
    const [categoryToggle, setCategoryToggle] = useState(false);


    useEffect(() => {
        async function fetchData() {
            const [unitData, resourceData] = await Promise.all([
                getUnitList(instance),
                getResourceList(instance),
            ]);

            setUnits(unitData);
            setResources(resourceData);
        }
        

        fetchData();
    }, [instance])

    const search = () => {
        let data = {
            
        }
    }

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <button className="btn" onClick={() => {
                        search();
                    }}
                    >
                        <Search /> Sök
                    </button>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <div className="row w-100">
                    <div className="col-12 col-md-6 col-lg-3">
                        <SelectCheckbox description="Enhet" setIsChecked={setUnitToggle} isChecked={unitToggle} setSelectedValue={setUnitId}>
                            <option value="" defaultValue></option>
                            {units.length > 0 && (
                                units.map((unit) => {
                                    return (
                                        <option key={unit.id} value={`${unit.id}`}>{unit.name}</option>
                                    );
                                })
                            )}
                        </SelectCheckbox>
                        <SelectCheckbox description="Resurs / Person" setIsChecked={setResourceToggle} isChecked={resourceToggle}>
                            <option defaultValue></option>
                            {resources.length > 0 && (
                                resources.map((resource) => {
                                    return (
                                        <option key={resource.id} value={`${resource.id}`}>{resource.name}</option>
                                    );
                                })
                            )}
                        </SelectCheckbox>
                        <CategoryCheckbox
                            setIsChecked={setCategoryToggle}
                            isChecked={categoryToggle}

                            setCategoryValue={setCategoryId}
                            categoryValue={categoryId}
                        />


                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <DateCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </DateCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                        <SelectCheckbox>
                            <option value="" defaultValue></option>
                            <option value="0">A</option>
                            <option value="1">B</option>
                            <option value="2">C</option>
                        </SelectCheckbox>
                    </div>
                </div>                
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {
    const categoryId = new URL(request.request.url).searchParams.get('categoryId') ?? null;
    const resourceId = new URL(request.request.url).searchParams.get('resourceId') ?? null;
    const date = new URL(request.request.url).searchParams.get('date') ?? new Date().toISOString().split('T')[0];

    return { cost: { categoryId, resourceId, date, isLocked: false } };
}
