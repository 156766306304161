import { getAccessToken } from './base.service';
import {
    getCustomer as getCustomerRepo,
    getCustomerList as getCustomerListRepo,
    createCustomer as createCustomerRepo,
    updateCustomer as updateCustomerRepo,
    deleteCustomer as deleteCustomerRepo,
} from './../repositories/repository';

export async function getCustomer(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getCustomerRepo(accessToken, id, includes);
}

export async function getCustomerList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCustomerListRepo(accessToken, filter);
}

export async function createCustomer(msalInstance, customer) {

    //console.log({ msalInstance, customer });
    const accessToken = await getAccessToken(msalInstance);
    return await createCustomerRepo(accessToken, customer);
}

export async function updateCustomer(msalInstance, customer, fields) {

    //console.log({ msalInstance, customer, fields });
    const accessToken = await getAccessToken(msalInstance);
    return await updateCustomerRepo(accessToken, customer, fields);
}

export async function deleteCustomer(msalInstance, id) {

    //console.log({ msalInstance, id });
    const accessToken = await getAccessToken(msalInstance);
    return await deleteCustomerRepo(accessToken, id);
}
