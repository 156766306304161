import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCostTypeList } from '../services/cost-type.service';
import 'react-select-search/style.css';
import SelectSearch from 'react-select-search';
import { errorHandler } from "../error";
import { classNames } from "./BaseSelectSearch";

//import { useSelect } from 'react-select-search';

export default function CostTypeControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [options, setOptions] = useState([]);
    const [allowEmpty] = useState(props.allowEmpty ?? false);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;

    useEffect(() => {
        const filter = { orderBy: [{ field: "sortOrder" }] };
        getCostTypeList(instance, filter).then((costTypes) => {
            if (!value && !allowEmpty) {
                let defaultCostType = costTypes.find((x) => (x.isDefault));
                if (defaultCostType) {
                    setValue(defaultCostType.id);
                    if (onChange) {
                        onChange(defaultCostType.id, defaultCostType);
                    }
                }
            }
            let items = costTypes.map((p) => ({ value: p.id, name: p.name }));
            if (allowEmpty) {
                items.unshift({ value: null, name: "" });
            }
            setOptions(items);
        }).catch(errorHandler);
    }, [allowEmpty, instance]);

    useEffect(() => {
        setReadOnly(props.readOnly ?? false);
        if (props.value !== undefined) {
            setValue(props.value);
        }
    }, [props]);

    return (
        <SelectSearch options={options} value={value} disabled={readOnly} placeholder="Välj typ" className={classNames} onChange={(value, item) => {
            setValue(value);
            if (props.onChange) {
                props.onChange(value, item);
            }
        }} />
    )
}

// https://www.npmjs.com/package/react-select-search