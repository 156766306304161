import { useRouteError } from "react-router-dom";
import AlertBox from "./helpers/alert.helper";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}

export function errorHandler(error) {

    if (error instanceof Promise) {
        error.then((result) => {
            if (result.showInGui) {
                alert(result.clientMessage);
            }
        });
    } else if (error instanceof Object) {
        console.log({ error });
        (<AlertBox title={"Felhantering"} message={ error} />)
        alert(error);
    }
    else {
        alert(error);
    }

    // console.log({ error });
    console.error({ error });
    //throw new Error(error);
    //return error;
}