import { useState, useEffect } from 'react'
import { useMsal } from "@azure/msal-react";
import { errorHandler } from "../error";
import { getCategory } from '../services/category.service';
import { Folder } from 'react-bootstrap-icons';
import { CategoryModalControl } from '../components/CategoryControl';

export default function CategoryCheckbox({ isChecked, setIsChecked, setCategoryId, setPartId, setProjectId, setCustomerId }) {
    const { instance } = useMsal();
    const [showInfo, setShowInfo] = useState(false);
    const [name, setName] = useState("");
    const [categoryValue, setCategoryValue] = useState(null);

    useEffect(() => {
        if (categoryValue) {
            getCategory(instance, categoryValue).then((cat) => {
                let text = cat.projectName + ' / ' + cat.partName + ' / ' + cat.namePath;
                setName(text);

                setCategoryId(cat.categoryId);
                setPartId(cat.partId);
                setProjectId(cat.projectId);
                setCustomerId(cat.customerId);

            }).catch(errorHandler);
        } else {
            setName("");
        }
    }, [categoryValue, instance, setCategoryId, setCustomerId, setPartId, setProjectId]);

    const handleCheckboxChange = (e) => {
        if (setIsChecked)
            setIsChecked(e.target.checked);
    };

    return (
        <div className="d-flex flex-column align-items-start w-100 my-2 mx-3">
            <p className="mb-0 ms-5">Kategori</p>
            <div className="input-group mb-1 w-100">
                <div className="input-group-text">
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        aria-label="Checkbox for following text input"
                    />
                </div>
                <input type="text" className="form-control" value={name} readOnly={true} onClick={() => { setShowInfo(!showInfo) }} />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" data-bs-toggle="modal" data-bs-target="#selectCategoryModal">
                        <Folder />
                    </button>
                </div>
                <CategoryModalControl value={categoryValue} onChange={(e) => { if (e) { setCategoryValue(e); } }} />
            </div>
        </div>
    );
}
