import { useState, useEffect } from 'react'
import {
    useLoaderData,
    useNavigate,
    NavLink,
} from "react-router-dom";
import { getCategory, createCategory, updateCategory, deleteCategory } from '../../services/category.service';
import { getPart } from '../../services/part.service';
import { Hdd, BoxArrowInLeft, Trash } from 'react-bootstrap-icons';
import { useMsal } from "@azure/msal-react";
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";
import { getFields } from "../../helpers/edit.helper";
import UnitControl from "../../components/UnitControl";
import CostTypeControl from "../../components/CostTypeControl";

export default function CategoryEdit() {
    const { category, part, parent } = useLoaderData();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [original, setOriginal] = useState({ ...category });
    const [entity, setEntity] = useState({ ...category });

    //console.log({ CategoryEdit: true, original, category, part, parent, entity });

    useEffect(() => {
        setOriginal({ ...category });
        setEntity({ ...category });
    }, [category]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            save(instance, entity, original).then(() => {
                                navigate(-1);
                            }).catch(errorHandler);
                        }}
                        >
                            <Hdd /> Spara
                        </button>
                        <button className="btn" onClick={() => {
                            navigate(-1);
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                    {!original.id ? null : (
                        <>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button className="btn" onClick={() => {
                                    remove(instance, original).then((result) => {
                                        if (result) { navigate(-1); }
                                    }).catch(errorHandler);
                                }}
                                >
                                    <Trash /> Ta bort
                                </button>
                            </div>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    Kategori
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink className="dropdown-item" to={"../../category?partId=" + original.partId}>Visa kategorier</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to={"../../category/create?partId=" + original.partId + "&parentId=" + original.parentId}>Skapa ny kategori</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to={"../../category/create?partId=" + original.partId + "&parentId=" + original.id}>Skapa ny underliggande kategori</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to={"../../cost/create?categoryId=" + original.id}>Skapa ny rapportering</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink title="Kund" to={"../../customer/" + part.project.customerId}>{part.project.customerName}</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink title="Projekt" to={"../../project/" + part.projectId}>{part.projectName}</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink title="Projektdel" to={"../../part/" + part.id}>{part.name}</NavLink>
                        </li>
                        {categoryBreadcrumb(parent)}
                        <li className="breadcrumb-item active" aria-current="page">Kategori</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Namn</label>
                        <input type="text" className="form-control" maxLength="100" value={entity.name ?? ""} onChange={(e) => {
                            setEntity({ ...entity, name: e.target.value });
                            // category.name = e.target.value;
                        }} />
                    </div>

                    {parent ? (
                        <>
                            <div className="w-100 d-block"></div>
                            <div className="col col-md-6 col-lg-4 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="isInheritedCheckBox" checked={entity.isInherited} onChange={(e) => {
                                        setEntity({ ...entity, isInherited: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="isInheritedCheckBox">
                                        Ingår i överliggande kategori
                                    </label>
                                </div>
                            </div>
                        </>
                    ) : null}

                    <div className="w-100 d-block"></div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Enhet</label>
                        <UnitControl value={entity.unitId} allowEmpty={true} readOnly={entity.isInherited} onChange={(unitId) => {
                            setEntity({ ...entity, unitId: unitId });
                            // category.unitId = unitId;
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Enhetspris</label>
                        <input type="number" className="form-control" value={entity.unitPrice} readOnly={entity.isInherited} onChange={(e) => {
                            setEntity({ ...entity, unitPrice: e.target.value });
                            //category.unitPrice = e.target.value;
                        }} />
                    </div>
                    <div className="col col-md-6 col-lg-4 mb-3">
                        <label className="form-label">&nbsp;</label>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" disabled={entity.isInherited} id="unitPriceCheckBox" checked={entity.isUnitPriceLocked} onChange={(e) => {
                                setEntity({ ...entity, isUnitPriceLocked: e.target.checked });
                                //category.isUnitPriceLocked = e.target.checked;
                            }} />
                            <label className="form-check-label" htmlFor="unitPriceCheckBox">
                                Enhetspris låst
                            </label>
                        </div>
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Antal</label>
                        <input type="number" className="form-control" value={entity.quantity} readOnly={entity.isInherited} onChange={(e) => {
                            setEntity({ ...entity, quantity: e.target.value });
                            //category.quantity = e.target.value;
                        }} />
                    </div>
                    <div className="col col-md-6 col-lg-4 mb-3">
                        <label className="form-label">&nbsp;</label>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" disabled={entity.isInherited} id="quantityCheckBox" checked={entity.isQuantityLocked} onChange={(e) => {
                                setEntity({ ...entity, isQuantityLocked: e.target.checked });
                                //category.isQuantityLocked = e.target.checked;
                            }} />
                            <label className="form-check-label" htmlFor="quantityCheckBox">
                                Antal låst
                            </label>
                        </div>
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Typ</label>
                        <CostTypeControl value={entity.costTypeId} allowEmpty={true} readOnly={entity.isInherited} onChange={(costTypeId) => {
                            setEntity({ ...entity, costTypeId: costTypeId });
                            //category.costTypeId = costTypeId;
                        }} />
                    </div>

                    <div className="w-100 d-block"></div>

                    <div className="col col-md-3 col-lg-2 mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" disabled={entity.isInherited} value="" id="descriptionCheckBox" checked={entity.isDescriptionRequired} onChange={(e) => {
                                setEntity({ ...entity, isDescriptionRequired: e.target.checked });
                                //category.isDescriptionRequired = e.target.checked;
                            }} />
                            <label className="form-check-label" htmlFor="descriptionCheckBox">
                                Beskrivning krävs
                            </label>
                        </div>
                    </div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="closedCheckBox" checked={entity.isClosed} onChange={(e) => {
                                setEntity({ ...entity, isClosed: e.target.checked });
                                //category.isClosed = e.target.checked;
                            }} />
                            <label className="form-check-label" htmlFor="closedCheckBox">
                                Stängd
                            </label>
                        </div>
                    </div>
                </div>
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {

    //console.log({ msalInstance: msalInstance, request: request });

    const category = await getCategory(msalInstance, request.params.id, ["Parent", "Part", "Part.Project"]);
    let p = category.parent;
    while (p) {
        p.parent = p.parentId ? await getCategory(msalInstance, p.parentId) : null;
        p = p.parent;
    }
    
    return { category, part: category.part, parent: category.parent };
}

export async function loaderCreate({ msalInstance, request }) {
    const partId = new URL(request.request.url).searchParams.get('partId');
    const parentId = new URL(request.request.url).searchParams.get('parentId') ?? null;
    const part = await getPart(msalInstance, partId, ["Project"]);
    const parent = parentId ? await getCategory(msalInstance, parentId) : null;
    let p = parent;
    while (p) {
        p.parent = p.parentId ? await getCategory(msalInstance, p.parentId) : null;
        p = p.parent;
    }

    return { category: { partId, parentId, unitId: null }, part, parent };
}

async function save(msalInstance, category, original) {

    //console.log({ msalInstance, category, original });
    let result;
    if (category.id) {
        let fields = getFields(category, original);
        result = await updateCategory(msalInstance, category, fields);
    }
    else {
        result = await createCategory(msalInstance, category);
    }

    //console.log({ result });
}

async function remove(msalInstance, original) {

    //console.log({ msalInstance, original });

    if (window.confirm("Är du säker på att du vill ta bort denna post?")) {
        await deleteCategory(msalInstance, original.id);
        return true;
    }

    return false;
}

function categoryBreadcrumb(cat) {

    if (!cat) return;

    return (
        <>
            {categoryBreadcrumb(cat.parent) }
            <li className="breadcrumb-item">
                <NavLink title="Kategori" to={"../../category/" + cat.id}>{cat.name}</NavLink>
            </li>
        </>
    );
}