import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCustomerListCreateInvoice as getCustomerList } from '../services/invoice.service';
import 'react-select-search/style.css';
import SelectSearch from 'react-select-search';
import { errorHandler } from "../error";
import { classNames } from "./BaseSelectSearch";

//import { useSelect } from 'react-select-search';

export default function CustomerCreateInvoiceControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [from, setFrom] = useState(props.from ?? null);
    const [to, setTo] = useState(props.to ?? null);
    const [options, setOptions] = useState([]);
    //const [options, setOptions] = useState([]);

    useEffect(() => {
        setValue(props.value ?? null);
        setFrom(props.from ?? null);
        setTo(props.to ?? null);
    }, [props]);

    useEffect(() => {
        getOptions();
    }, [value, from, to]);

    async function getOptions() {
        const filter = { from, to };
        var customers = await getCustomerList(instance, filter);
        setOptions(customers.map((p) => ({ value: p.id, name: p.name })));
    };

    return (
        <SelectSearch options={options} value={value} placeholder="Välj kund" className={classNames} onChange={(value, item) => {
            //console.log({ value, item });
            setValue(value);
            if (props.onChange) {
                props.onChange(value, item);
            }
        }} />
    )
}

// https://www.npmjs.com/package/react-select-search