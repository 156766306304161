import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
//import Root, { loader as rootLoader } from "./routes/root";

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

//import { BrowserRouter, HashRouter, Switch, Route } from "react-router-dom";
// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorPage from './error';
//import Contact from './routes/customer';
import Index from "./routes/index";

import CategoryIndex, {
    loader as categoryIndexLoader,
} from './routes/category/index';
import CategoryEdit, {
    loader as categoryEditLoader,
    loaderCreate as categoryCreateLoader,
} from './routes/category/edit';

import CostIndex, {
    loader as costIndexLoader,
} from './routes/cost/index';
import CostList, {
    loader as costListLoader,
} from './routes/cost/list';
import CostEdit, {
    loader as costEditLoader,
    loaderCreate as costCreateLoader,
} from './routes/cost/edit';
import CostSummary, {
    loader as costSummaryLoader,
} from './routes/cost/summary';

import CustomerIndex, {
    loader as customerIndexLoader,
} from './routes/customer/index';
import CustomerEdit, {
    loader as customerEditLoader,
    loaderCreate as customerCreateLoader,
} from './routes/customer/edit';

import InvoiceIndex, {
    loader as invoiceIndexLoader,
} from './routes/invoice/index';
import InvoiceEdit, {
    loader as invoiceEditLoader,
} from './routes/invoice/edit';
import InvoiceCreate, {
    loader as invoiceCreateLoader,
} from './routes/invoice/create';

import PartIndex, {
    loader as partIndexLoader,
} from './routes/part/index';
import PartEdit, {
    loader as partEditLoader,
    loaderCreate as partCreateLoader,
} from './routes/part/edit';

import ProjectIndex, {
    loader as projectIndexLoader,
} from './routes/project/index';
import ProjectEdit, {
    loader as projectEditLoader,
    loaderCreate as projectCreateLoader,
} from './routes/project/edit';

import FortnoxIndex, {
    loader as fortnoxIndexLoader,
} from './routes/fortnox/index';
import FortnoxCredentials, {
    loader as fortnoxCredentialsLoader,
} from './routes/fortnox/credentials';
import FortnoxCode, {
    loader as fortnoxCodeLoader,
} from './routes/fortnox/code';


const msalInstance = new PublicClientApplication(msalConfig);
const createRouter = ({ msalInstance }) => createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            element={<App />}
            /*loader={rootLoader}*/
            errorElement={<ErrorPage />}
        >
            {/*<Route errorElement={<ErrorPage />}>*/}
            <Route index element={<Index />} />
            <Route
                path="/category"
                loader={async (request) => await categoryIndexLoader({ msalInstance, request })}
                element={<CategoryIndex />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/category/create"
                loader={async (request) => await categoryCreateLoader({ msalInstance, request })}
                element={<CategoryEdit key={ Math.random() } />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/category/:id"
                loader={async (request) => await categoryEditLoader({ msalInstance, request })}
                element={<CategoryEdit key={Math.random()} />}
                errorElement={<ErrorPage />}
            />

            <Route
                path="/cost"
                loader={async (request) => await costIndexLoader({ msalInstance, request })}
                element={<CostIndex />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/cost/list"
                loader={async (request) => await costListLoader({ msalInstance, request })}
                element={<CostList />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/cost/create"
                loader={async (request) => await costCreateLoader({ msalInstance, request })}
                element={<CostEdit />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/cost/:id"
                loader={async (request) => await costEditLoader({ msalInstance, request })}
                element={<CostEdit />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/cost/summary"
                loader={async (request) => await costSummaryLoader({ msalInstance, request })}
                element={<CostSummary />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/customer"
                loader={async (request) => await customerIndexLoader({ msalInstance, request })}
                element={<CustomerIndex />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/customer/create"
                loader={async (request) => await customerCreateLoader({ msalInstance, request })}
                element={<CustomerEdit />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/customer/:id"
                loader={async (request) => await customerEditLoader({ msalInstance, request })}
                element={<CustomerEdit />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/fortnox"
                loader={async (request) => await fortnoxIndexLoader({ msalInstance, request })}
                element={<FortnoxIndex />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/fortnox/credentials"
                loader={async (request) => await fortnoxCredentialsLoader({ msalInstance, request })}
                element={<FortnoxCredentials />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/fortnox/code"
                loader={async (request) => await fortnoxCodeLoader({ msalInstance, request })}
                element={<FortnoxCode />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/invoice"
                loader={async (request) => await invoiceIndexLoader({ msalInstance, request })}
                element={<InvoiceIndex />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/invoice/create"
                loader={async (request) => await invoiceCreateLoader({ msalInstance, request })}
                element={<InvoiceCreate />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/invoice/:id"
                loader={async (request) => await invoiceEditLoader({ msalInstance, request })}
                element={<InvoiceEdit />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/part"
                loader={async (request) => await partIndexLoader({ msalInstance, request })}
                element={<PartIndex />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/part/create"
                loader={async (request) => await partCreateLoader({ msalInstance, request })}
                element={<PartEdit />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/part/:id"
                loader={async (request) => await partEditLoader({ msalInstance, request })}
                element={<PartEdit />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/project"
                loader={async (request) => await projectIndexLoader({ msalInstance, request })}
                element={<ProjectIndex />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/project/create"
                loader={async (request) => await projectCreateLoader({ msalInstance, request })}
                element={<ProjectEdit />}
                errorElement={<ErrorPage />}
            />
            <Route
                path="/project/:id"
                loader={async (request) => await projectEditLoader({ msalInstance, request })}
                element={<ProjectEdit />}
                errorElement={<ErrorPage />}
            />
            {/*</Route>*/}
        </Route>
    )
);
const root = ReactDOM.createRoot(document.getElementById('root'));

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <RouterProvider router={createRouter({ msalInstance })} />
        </MsalProvider>
    </React.StrictMode>
);
