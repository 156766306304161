import { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCustomerList } from '../services/customer.service';
import 'react-select-search/style.css';
import SelectSearch from 'react-select-search';
import { errorHandler } from "../error";
import { classNames } from "./BaseSelectSearch";

//import { useSelect } from 'react-select-search';

export default function CustomerControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    //const [options, setOptions] = useState([]);

    //useEffect(() => {
    //    const filter = {};
    //    console.log({ value });
    //    getCustomerList(instance, filter).then((customers) => {
    //        setOptions(customers.map((p) => ({ value: p.id, name: p.name })));
    //    }).catch(errorHandler);
    //}, []);

    function getOptions(query) {
        //console.log({ query });
        return new Promise((resolve, reject) => {
            const filter = { searchTerm: query };
            getCustomerList(instance, filter).then((customers) => {
                resolve(customers.map((p) => ({ value: p.id, name: p.name })));
            }).catch((error) => {
                errorHandler(error); reject(error);
            });
        });
    };

    return (
        <SelectSearch getOptions={getOptions} value={value} search={true} placeholder="Välj kund" className={classNames} onChange={(value, item) => {
            //console.log({ value, item });
            setValue(value);
            if (props.onChange) {
                props.onChange(value, item);
            }
        }} />
    )
}

// https://www.npmjs.com/package/react-select-search