import { getAccessToken } from './base.service';
import {
    getCostType as getCostTypeRepo,
    getCostTypeList as getCostTypeListRepo,
} from './../repositories/repository';

export async function getCostType(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getCostTypeRepo(accessToken, id, includes);
}

export async function getCostTypeList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCostTypeListRepo(accessToken, filter);
}
