import React, { useState, useEffect, useRef } from 'react';
import {
    Outlet,
} from "react-router-dom";

import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import './App.css';

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */


/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    let container = useRef(null);
    // const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [appHeight, setAppHeight] = useState(null);
    useEffect(() => {
        const handleWindowResize = () => {
            setAppHeight(window.innerHeight - container.current.offsetTop);
        };

        window.addEventListener('resize', handleWindowResize);
        setAppHeight(window.innerHeight - container.current.offsetTop);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className="App" ref={container} style={{ height: appHeight }}>
            <AuthenticatedTemplate>
                <Outlet />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Logga in för att komma åt Torin</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
