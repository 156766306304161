import React, { useState } from "react";
// import { useMsal } from "@azure/msal-react";
import {
    useLoaderData,
    useNavigate,
    NavLink,
} from "react-router-dom";
import { getPartList } from '../../services/part.service';
import { getProject } from '../../services/project.service';
//import { serializeQueryString } from "../../helpers/url.helper";

import BaseListControl from '../../components/BaseListControl'
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl from "../../components/ToolbarControl";
import { PlusCircle } from 'react-bootstrap-icons';

const columns = [
    {
        headerName: "Nummer",
        field: "number",
        filter: true,
        // sort: "disable",
    },
    {
        headerName: "Namn",
        field: "name",
        filter: true,
    },
    {
        headerName: "Status",
        field: "partStatusName",
        filter: true,
    },
]
let navigate;

export default function PartIndex() {
    // const { instance } = useMsal();
    const { partList, loaderFilter, project } = useLoaderData();
    //const [filter] = useState(loaderFilter);
    navigate = useNavigate();

    //const search = () => {
    //    console.log({ filter });
    //    navigate("?" + serializeQueryString(filter));
    //};

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            navigate("create?projectId=" + project.id);
                        }}
                        >
                            <PlusCircle /> Skapa
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink title="Kund" to={"../../customer/" + project.customerId}>{project.customerName}</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink title="Projekt" to={"../../project/" + project.id}>{project.name}</NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Projektdelar</li>
                    </ol>
                </nav>
                <BaseListControl columns={columns} list={partList} onRowClicked={rowClicked} />
            </CardBodyControl>
        </CardControl>

    );
}

function rowClicked(e) {
    const id = e.data.id;
    navigate("/part/" + id);
}

export async function loader({ msalInstance, request, params }) {

    //console.log({ msalInstance: msalInstance, request: request.request, params: request.params });

    let filter = {
        projectId: new URL(request.request.url).searchParams.get('projectId'),
    }
    const partList = await getPartList(msalInstance, filter);
    const project = await getProject(msalInstance, filter.projectId);

    return { partList, loaderFilter: filter, project };
}
