export default function DateCheckbox({ isChecked, setIsChecked, dateValue, setDateValue, description }) {
    const handleCheckboxChange = (e) => {
        if(setIsChecked)
            setIsChecked(e.target.checked);
    };

    const handleSelectChange = (e) => {
        if(setDateValue)
            setDateValue(e.target.value);
    };

    return (
        <div className="d-flex flex-column align-items-start w-100 my-2 mx-3">
            {description && (
                <p className="mb-0 ms-5">
                    {description}
                </p>
            )}
            <div className="input-group mb-1">
                <div className="input-group-text">
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        value=""
                        aria-label="Checkbox for following text input"
                    />
                </div>
                <input
                    type="date"
                    className="form-control"
                    aria-label="Text input with checkbox"
                />
            </div>
        </div>
    );
}