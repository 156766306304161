import { getAccessToken } from './base.service';
import {
    getPart as getPartRepo,
    getPartList as getPartListRepo,
    createPart as createPartRepo,
    updatePart as updatePartRepo,
    deletePart as deletePartRepo,
} from './../repositories/repository';

export async function getPart(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getPartRepo(accessToken, id, includes);
}

export async function getPartList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getPartListRepo(accessToken, filter);
}

export async function createPart(msalInstance, part) {

    //console.log({ msalInstance, part });
    const accessToken = await getAccessToken(msalInstance);
    return await createPartRepo(accessToken, part);
}

export async function updatePart(msalInstance, part, fields) {

    //console.log({ msalInstance, part, fields });
    const accessToken = await getAccessToken(msalInstance);
    return await updatePartRepo(accessToken, part, fields);
}

export async function deletePart(msalInstance, id) {

    //console.log({ msalInstance, id });
    const accessToken = await getAccessToken(msalInstance);
    return await deletePartRepo(accessToken, id);
}
