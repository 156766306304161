import { getAccessToken } from './base.service';
import {
    getUser as getUserRepo,
    getUserList as getUserListRepo,
    createUser as createUserRepo,
    updateUser as updateUserRepo,
    deleteUser as deleteUserRepo,
} from './../repositories/repository';

export async function getUser(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getUserRepo(accessToken, id, includes);
}

export async function getUserList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getUserListRepo(accessToken, filter);
}

export async function createUser(msalInstance, user) {

    //console.log({ msalInstance, user });
    const accessToken = await getAccessToken(msalInstance);
    return await createUserRepo(accessToken, user);
}

export async function updateUser(msalInstance, user, fields) {

    //console.log({ msalInstance, user, fields });
    const accessToken = await getAccessToken(msalInstance);
    return await updateUserRepo(accessToken, user, fields);
}

export async function deleteUser(msalInstance, id) {

    //console.log({ msalInstance, id });
    const accessToken = await getAccessToken(msalInstance);
    return await deleteUserRepo(accessToken, id);
}
