import { getAccessToken } from './base.service';
import {
    getUnit as getUnitRepo,
    getUnitList as getUnitListRepo,
} from './../repositories/repository';

export async function getUnit(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getUnitRepo(accessToken, id, includes);
}

export async function getUnitList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getUnitListRepo(accessToken, filter);
}
