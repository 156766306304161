import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getUnitList } from '../services/unit.service';
import 'react-select-search/style.css';
import SelectSearch from 'react-select-search';
import { errorHandler } from "../error";
import { classNames } from "./BaseSelectSearch";

//import { useSelect } from 'react-select-search';

export default function UnitControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [options, setOptions] = useState([]);
    const [allowEmpty] = useState(props.allowEmpty ?? false);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;

    useEffect(() => {
        const filter = { orderBy: [{ field: "sortOrder" }] };
        //console.log({value});
        getUnitList(instance, filter).then((units) => {
            if (!value && !allowEmpty) {
                let defaultUnit = units.find((x) => (x.isDefault));
                if (defaultUnit) {
                    setValue(defaultUnit.id);
                    if (onChange) {
                        onChange(defaultUnit.id, defaultUnit);
                    }
                }
            }
            let items = units.map((p) => ({ value: p.id, name: p.name, data: p }));
            if (allowEmpty) {
                items.unshift({ value: null, name: "" });
            }
            setOptions(items);
        }).catch(errorHandler);
    //}, [allowEmpty, instance, onChange]);
    }, [allowEmpty, instance]);

    useEffect(() => {
        setReadOnly(props.readOnly ?? false);
        if (props.value !== undefined) {
            setValue(props.value);
        }
    }, [props]);

    return (
        <SelectSearch options={options} value={value} disabled={readOnly} placeholder="Välj enhet" className={classNames} onChange={(v, item) => {
            //console.log({ v, item });
            setValue(v);
            if (onChange) {
                onChange(v, item);
            }
        }} />
    )
}

// https://www.npmjs.com/package/react-select-search