import { useState, useEffect } from 'react'
import {
    useLoaderData,
    useNavigate,
    NavLink,
} from "react-router-dom";
import { getInvoice, createInvoice, updateInvoice, deleteInvoice, sendToFortnox } from '../../services/invoice.service';
import { Hdd, BoxArrowInLeft, Trash, Send } from 'react-bootstrap-icons';
import { useMsal } from "@azure/msal-react";
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";
import { getFields, formatCurrency } from "../../helpers/edit.helper";

export default function InvoiceEdit() {
    const { invoice } = useLoaderData();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [original, setOriginal] = useState({ ...invoice });
    const [entity, setEntity] = useState({ ...invoice });
    const [isLocked, setIsLocked] = useState(invoice.isLocked || invoice.statusCode === "registered");

    //console.log({ InvoiceEdit: true, original, invoice, entity, category });

    useEffect(() => {
        //console.log("UPDATE original and entity");
        setOriginal({ ...invoice });
        setEntity({ ...invoice });
        setIsLocked(invoice.isLocked || invoice.statusCode === "registered");
    }, [invoice]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        {isLocked ? null : (
                            <button className="btn" onClick={() => {
                                save(instance, entity, original).then(() => {
                                    navigate("/invoice");
                                }).catch(errorHandler);
                            }}
                            >
                                <Hdd /> Spara
                            </button>
                        )}
                        <button className="btn" onClick={() => {
                            navigate("/invoice");
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                    {!original.isCanceled ? null : (
                        <>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button className="btn" onClick={() => {
                                    cancel(instance, original).then((result) => {
                                        if (result) { navigate("/invoice"); }
                                    }).catch(errorHandler);
                                }}
                                >
                                    <Trash /> Makulera
                                </button>
                            </div>
                        </>
                    )}
                    {isLocked ? null : (
                        <>
                            <ToolbarSeparatorControl />
                            <button className="btn" onClick={() => {
                                send(instance, original.id).then(() => {
                                    navigate("/invoice");
                                }).catch(errorHandler);
                            }}
                            >
                                <Send /> Skicka till Fortnox
                            </button>
                        </>
                    )}

                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Redigera faktura</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Fakturanummer</label>
                        <input type="text" className="form-control" value={entity.number} readOnly={true} />
                    </div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Status {entity.isCanceled ? "(Makulerad)" : null}</label>
                        <input type="text" className="form-control" value={entity.statusName} readOnly={true} />
                    </div>

                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Fakturadatum</label>
                        <input type="date" className="form-control" value={entity.invoiceDate ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, invoiceDate: e.target.value });
                        }} />
                    </div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Förfallodatum</label>
                        <input type="date" className="form-control" value={entity.dueDate ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, dueDate: e.target.value });
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Netto</label>
                        <input type="text" className="form-control" value={formatCurrency(entity.netAmount)} readOnly={true} />
                    </div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Moms</label>
                        <input type="text" className="form-control" value={formatCurrency(entity.vatAmount)} readOnly={true} />
                    </div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Brutto</label>
                        <input type="text" className="form-control" value={formatCurrency(entity.grossAmount)} readOnly={true} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-6 col-lg-4 mb-3">
                        <label className="form-label">Kund (namn)</label>
                        <input type="text" className="form-control" maxLength={100} value={entity.customerName ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, customerName: e.target.value });
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-6 col-lg-4 mb-3">
                        <label className="form-label">Adress</label>
                        <input type="text" className="form-control" maxLength={100} value={entity.address ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, address: e.target.value });
                        }} />
                        <input type="text" className="form-control" maxLength={100} value={entity.address1 ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, address1: e.target.value });
                        }} />
                        <input type="text" className="form-control" maxLength={100} value={entity.address2 ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, address2: e.target.value });
                        }} />
                        <input type="text" className="form-control" maxLength={100} value={entity.address3 ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, address3: e.target.value });
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Postnummer</label>
                        <input type="text" className="form-control" maxLength={20} value={entity.zipCode ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, zipCode: e.target.value });
                        }} />
                    </div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Postort</label>
                        <input type="text" className="form-control" maxLength={50} value={entity.city ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, city: e.target.value });
                        }} />
                    </div>
                    <div className="col col-md-6 col-lg-4 mb-3">
                        <label className="form-label">Vår referens</label>
                        <input type="text" className="form-control" maxLength={100} value={entity.ourReferenceName ?? ""} readOnly={isLocked} onChange={(e) => {
                            setEntity({ ...entity, ourReferenceName: e.target.value });
                        }} />
                    </div>

                </div>
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {

    //console.log({ msalInstance: msalInstance, request: request });

    const invoice = await getInvoice(msalInstance, request.params.id);
    return { invoice };
}

async function save(msalInstance, invoice, original) {

    //console.log({ msalInstance, invoice, original });
    let result;
    if (invoice.id) {
        let fields = getFields(invoice, original);
        result = await updateInvoice(msalInstance, invoice, fields);
    }
    else {
        result = await createInvoice(msalInstance, invoice);
    }

    return result;
}
async function send(msalInstance, id) {

    //console.log({ msalInstance, invoice, original });
    let result = await sendToFortnox(msalInstance, id);
    return result;
}
async function cancel(msalInstance, original) {

    //console.log({ msalInstance, original });

    if (window.confirm("Är du säker på att du vill makulera denna post?")) {
        await deleteInvoice(msalInstance, original.id);
        return true;
    }

    return false;
}
