export const classNames = {
    "container": "w-100 select-search-container",
    "value": "select-search-value",
    "input": "form-control", // select-search-input",
    "select": "select-search-select",
    "options": "select-search-options",
    "row": "dropdown-item select-search-row",
    "option": "dropdown-item select-search-option",
    "group": "select-search-group",
    "group-header": "select-search-group-header",
    "is-selected": "select-search-is-selected",
    "is-highlighted": "select-search-is-highlighted",
    "is-loading": "select-search-is-loading",
    "is-multiple": "select-search-is-multiple",
    "has-focus": "select-search-has-focus"
};

