export default function ToolbarControl({ children }) {
    return (
        <div className="btn-toolbar" role="toolbar">
            {children}
        </div>
    )
}

export function ToolbarSeparatorControl() {
    return (
        <div className="border-start border-end my-1 mr-2"></div>
    )
}