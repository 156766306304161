import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { getUserList, getUser } from '../services/user.service';
import 'react-select-search/style.css';
import SelectSearch from 'react-select-search';
import { errorHandler } from "../error";
import { classNames } from "./BaseSelectSearch";

//import { useSelect } from 'react-select-search';

export default function UserControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;

    useEffect(() => {
        setReadOnly(props.readOnly ?? false);
    }, [props]);

    function getOptions(query) {
        //console.log({ query });
        return new Promise((resolve, reject) => {
            const filter = { searchTerm: query };
            getUserList(instance, filter).then((users) => {
                resolve(users.map((p) => ({ value: p.id, name: p.name })));
            }).catch((error) => {
                errorHandler(error); reject(error);
            });
        });
    };

    useEffect(() => {
        if (!value) {
            getUser(instance, 'default').then((result) => {
                if (result) {
                    setValue(result.id);
                    if (onChange) {
                        onChange(result.id, { value: result.id, name: result.name });
                    }
                }
            }).catch((error) => {
                errorHandler(error);
            });
        }
    }, [instance, value, onChange]);

    return (
        <SelectSearch getOptions={getOptions} value={value} disabled={readOnly} search={true} placeholder="Välj resurs" className={classNames} onChange={(val, item) => {
            setValue(val);
            if (onChange) {
                onChange(val, item);
            }
        }} />
    )
}

// https://www.npmjs.com/package/react-select-search