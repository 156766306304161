import { useState, useEffect, useCallback, useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import { getPartListCreateInvoice } from '../services/invoice.service';
import 'react-select-search/style.css';
import { errorHandler } from "../error";
import BaseListControl, { currencyFormatter } from "./BaseListControl";

//import { useSelect } from 'react-select-search';

export default function PartCreateInvoiceControl(props) {
    const { instance } = useMsal();
    const [from, setFrom] = useState(props.from ?? null);
    const [to, setTo] = useState(props.to ?? null);
    const [customerId, setCustomerId] = useState(props.customerId ?? null);

    const partRef = useRef();
    const [partList, setPartList] = useState([]);
    const [partId, setPartId] = useState(props.partId ?? []);
    const onPartSelectionChanged = props.onPartSelectionChanged;

    const onSelectionChanged = useCallback((event) => {
        let ids = event.api.getSelectedRows().map((p) => p.id);
        setPartId(ids);
        onPartSelectionChanged(ids);
    }, [onPartSelectionChanged]);
    const onRowDataUpdated = useCallback((event) => {
        if (!event) return;
        if (!partId || partId.length === 0) {
            event.api.selectAll();
            return;
        }

        const selected = [];
        const deselected = [];
        event.api.forEachNode((node) => {
            if (partId.some((x) => x === node.data.id)) {
                selected.push(node);
            }
            else {
                deselected.push(node);
            }
        });
        //if (selected.length > 0) {
            event.api.setNodesSelected({ nodes: selected, newValue: true });
            event.api.setNodesSelected({ nodes: deselected, newValue: false });
    //    } else {
    //        event.api.selectAll();
    //    }
    }, [partId]);

    useEffect(() => {
        setCustomerId(props.customerId ?? null);
        setFrom(props.from ?? null);
        setTo(props.to ?? null);
        setPartId(props.partId ?? []);
    }, [props]);
    useEffect(() => {
        var f = { from, to, customerId };
        if (customerId) {
            getPartListCreateInvoice(instance, f).then((result) => {
                setPartList(result);
            }).catch(errorHandler);
        }
    }, [instance, from, to, customerId]);

    return (
        <BaseListControl gridRef={partRef} columns={partColumnsDef} list={partList} rowSelection="multiple" onSelectionChanged={onSelectionChanged} onRowDataUpdated={onRowDataUpdated} tableName="SkapaFaktura2" />
    )
}

const partColumnsDef = [
    {
        headerName: "",
        checkboxSelection: true,
        width: 40,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: true,
    },
    {
        headerName: "Projektdelsnr",
        field: "number",
        width: 135,
    },
    {
        headerName: "Projektdel",
        field: "name",
        width: 400,
    },
    {
        headerName: "Belopp",
        field: "netAmount",
        valueFormatter: currencyFormatter,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "From",
        field: "fromDate",
        width: 110,
    },
    {
        headerName: "Tom",
        field: "toDate",
        width: 110,
    },
];
