import React, { useState, useEffect, useCallback } from "react";
import {
    NavLink,
    useLoaderData,
    useNavigate,
} from "react-router-dom";
//import { getAccessToken as getAccessTokenRepo } from "../../repositories/fortnox.repository";
import { useMsal } from "@azure/msal-react";
import { ShieldCheck, BoxArrowInLeft } from 'react-bootstrap-icons';
import {
    getClientId,
    updateCredentials,
    refreshAccessToken
} from '../../services/fortnox.service';
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";

export default function Index() {

    const { code, state, currentUrl } = useLoaderData();
    const { instance } = useMsal();
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);
    //const onRefreshAccessToken = useCallback(() => {
    //    const entity = { code, redirectUrl: currentUrl }
    //    refreshAccessToken(instance, entity).then((result) => {
    //        navigate("/invoice");
    //    }).catch(errorHandler);
    //}, [code, currentUrl]);
    useEffect(() => {
            loadData();
    }, []);

    function loadData() {
        const entity = { code, redirectUrl: currentUrl }
        refreshAccessToken(instance, entity).then((result) => {
            navigate("/invoice");
            //window.open("/invoice", "_top");
        }).catch(errorHandler);
    }

    return (null);
    //return (
    //    <CardControl className="border-0">
    //        <CardHeaderControl>
    //            <ToolbarControl>
    //                <div className="btn-group" role="group">
    //                    {/*<button className="btn" onClick={onRefreshAccessToken}>*/}
    //                    {/*    <ShieldCheck /> Hämta Access Token*/}
    //                    {/*</button>*/}
    //                    <button className="btn" onClick={() => {
    //                        navigate("/invoice");
    //                    }}
    //                    >
    //                        <BoxArrowInLeft /> Avbryt
    //                    </button>
    //                </div>
    //            </ToolbarControl>
    //        </CardHeaderControl>
    //        <CardBodyControl>
    //            <nav aria-label="breadcrumb">
    //                <ol className="breadcrumb">
    //                    <li className="breadcrumb-item active" aria-current="page">Hämta Access Token</li>
    //                </ol>
    //            </nav>
    //        </CardBodyControl>
    //    </CardControl>
    //);
}

export async function loader({ msalInstance, request, params }) {

    let code = new URL(request.request.url).searchParams.get('code');
    let state = new URL(request.request.url).searchParams.get('state');
    let url = new URL(request.request.url);
    let currentUrl = url.protocol + '//' + url.host + url.pathname;

    return { code, state, currentUrl };
}
