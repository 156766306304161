// import React, { useState } from "react";
import {
    useLoaderData,
    useNavigate,
} from "react-router-dom";
import { getCustomerList } from '../../services/customer.service';
//import DataTable from "react-data-table-component";
//import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
//import { AgGridReact } from 'ag-grid-react';
//import "ag-grid-community/styles/ag-grid.css";
//import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import BaseListControl from '../../components/BaseListControl'
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl from "../../components/ToolbarControl";
import { PlusCircle } from 'react-bootstrap-icons';

/**
 * Renders information about the user obtained from TorinAPI
 * @param props 
 */
//export const CustomerListControl = (props) => {
//    console.log({ props: props });
//    return (
//        <div id="profile-div">
//            <table>
//                <thead>
//                    <tr>
//                        <th>Nummer</th>
//                        <th>Namn</th>
//                    </tr>
//                </thead>
//                <tbody>
//                {props ? props.list.map(item => (
//                    <tr key={ item.id }>
//                            <td>{item.number}</td>
//                            <td>{item.name}</td>
//                        </tr>
//                )) : null}
//                </tbody>
//            </table>
//        </div>
//    );
//};


const columns = [
    {
        headerName: "Nummer",
        field: "number",
        filter: true,
        // sort: "disable",
    },
    {
        headerName: "Alias",
        field: "alias",
        filter: true,
    },
    {
        headerName: "Namn",
        field: "name",
        width: 432,
        filter: true,
    },
    {
        headerName: "Samma faktura o besöksadress",
        field: "isVisitAndInvoiceAddressEqual",
        width: 245,
        filter: true,
    }
]
let navigate;

//const data = (customerList) => {

//    return {
//        columns: columns,
//        rows: customerList
//    }
//}


export default function CustomerIndex() {
    // const { customerList, setCustomerList } = useState(null);
    const { customerList } = useLoaderData();
    navigate = useNavigate();
    // console.log({ instance: instance, accounts: accounts });

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            navigate("create");
                        }}
                        >
                            <PlusCircle /> Skapa
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <BaseListControl columns={columns} list={customerList} onRowClicked={rowClicked} tableName="Kunder" />
            </CardBodyControl>
        </CardControl>

        //<div className="ag-theme-quartz" style={{ height: 500 }}>
        //    <AgGridReact rowData={customerList} columnDefs={columns} />
        //</div>
        //<CDBContainer>
        //    <CDBCard>
        //        <CDBCardBody>
        //            <CDBDataTable
        //                striped
        //                bordered
        //                hover
        //                entriesOptions={[5, 20, 25]}
        //                entries={5}
        //                pagesAmount={4}
        //                data={data(customerList)}
        //                materialSearch={true}
        //            />
        //        </CDBCardBody>
        //    </CDBCard>
        //</CDBContainer>

        //    <div id="profile-div">
        //        <table>
        //            <thead>
        //                <tr>
        //                    <th>Nummer</th>
        //                    <th>Namn</th>
        //                </tr>
        //            </thead>
        //            <tbody>
        //                {customerList ? customerList.map(item => (
        //                    <tr key={item.id}>
        //                        <td>{item.number}</td>
        //                        <td>{item.name}</td>
        //                    </tr>
        //                )) : null}
        //            </tbody>
        //        </table>
        //    </div>
    );
}

function rowClicked(e) {
    const id = e.data.id;
    navigate("/customer/" + id);
}

export async function loader({ msalInstance, request, params }) {

    //console.log({ msalInstance: msalInstance, request: request, params: params });

    let filter = {
        //pageSize: 10
    }
    const customerList = await getCustomerList(msalInstance, filter);
    return { customerList };
}
