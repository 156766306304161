import { getAccessToken } from './base.service';
import {
    getInvoice as getInvoiceRepo,
    getInvoiceList as getInvoiceListRepo,
    createInvoice as createInvoiceRepo,
    updateInvoice as updateInvoiceRepo,
    deleteInvoice as deleteInvoiceRepo,

    getCustomerListCreateInvoice as getCustomerListCreateInvoiceRepo,
    getPartListCreateInvoice as getPartListCreateInvoiceRepo,
    getCategoryListCreateInvoice as getCategoryListCreateInvoiceRepo,
    getCostListCreateInvoice as getCostListCreateInvoiceRepo,
    sendInvoiceToFortnox as sendInvoiceToFortnoxRepo,
} from './../repositories/repository';

export async function getInvoice(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getInvoiceRepo(accessToken, id, includes);
}

export async function getInvoiceList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getInvoiceListRepo(accessToken, filter);
}

export async function createInvoice(msalInstance, invoice) {

    //console.log({ msalInstance, invoice });
    const accessToken = await getAccessToken(msalInstance);
    return await createInvoiceRepo(accessToken, invoice);
}

export async function updateInvoice(msalInstance, invoice, fields) {

    //console.log({ msalInstance, invoice, fields });
    const accessToken = await getAccessToken(msalInstance);
    return await updateInvoiceRepo(accessToken, invoice, fields);
}

export async function deleteInvoice(msalInstance, id) {

    //console.log({ msalInstance, id });
    const accessToken = await getAccessToken(msalInstance);
    return await deleteInvoiceRepo(accessToken, id);
}

export async function getCustomerListCreateInvoice(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCustomerListCreateInvoiceRepo(accessToken, filter);
}

export async function getPartListCreateInvoice(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getPartListCreateInvoiceRepo(accessToken, filter);
}

export async function getCategoryListCreateInvoice(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCategoryListCreateInvoiceRepo(accessToken, filter);
}

export async function getCostListCreateInvoice(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getCostListCreateInvoiceRepo(accessToken, filter);
}

export async function sendToFortnox(msalInstance, id) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await sendInvoiceToFortnoxRepo(accessToken, id);
}
