export function serializeQueryString(obj, prefix, isArray) {
    var str = [];
    for (var param in obj) {
        if (obj.hasOwnProperty(param)) {
            var isParamNumber = !isNaN(param);
            var value = obj[param];
            //if (moment.isMoment(value)) {
            //    value = value.isValid() ? value.format() : null;
            //}
            var paramValue = isParamNumber ? '[' + param + ']' : param;
            var key = paramValue;
            if (prefix) {
                key = isArray ? prefix + '.' + paramValue : prefix + paramValue;
            }
            if (value !== null && value !== undefined) {
                var qs = (typeof value === "object") ?
                    serializeQueryString(value, key, isParamNumber) :
                    encodeURIComponent(key) + "=" + encodeURIComponent(value);
                if (qs) { str.push(qs); }
            }
        }
    }
    return str.join("&");
}
